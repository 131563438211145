import React from 'react'
import {
  InlineReactionButtons,
  InlineReactionButtonsConfig,
} from 'sharethis-reactjs'

import './ReactionButtons.scss'

export default function ReactionButtons({ url }: { url: string }) {
  const config: InlineReactionButtonsConfig = {
    alignment: 'left',
    enabled: true,
    language: 'en',
    min_count: 0,
    padding: 8,
    radius: 0,
    show_total: true,
    size: 32,
    reactions: [
      'slight_smile',
      'heart_eyes',
      'laughing',
      'astonished',
      'sob',
      'rage',
    ],
    url: url,
  }

  return <InlineReactionButtons config={config} />
}
