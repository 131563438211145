import React from 'react'
import { Helmet } from 'react-helmet'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Layout from 'components/Layout'
import LinkBack from 'components/LinkBack'
import PostAuthorAndDate from 'components/PostAuthorAndDate'
import ReactionButtons from 'components/ReactionButtons'

import 'styles/news/post-template.scss'

type PostTemplateProps = {
  pageContext: {
    article: {
      date: string
      title: string
      author: string
      topics: {
        title: string
        slug: string
        isInMenu: boolean
      }[]
      mainImage: string
      body: string
      originalArticleUrl: string
    }
    webview?: boolean
  }
  location: {
    href: string
  }
}

export default function PostTemplate({
  pageContext: {
    article: {
      date,
      title,
      author,
      topics,
      mainImage,
      body,
      originalArticleUrl,
    },
    webview,
  },
  location: { href },
}: PostTemplateProps) {
  const originalArticleDomain = originalArticleUrl
    ? new URL(originalArticleUrl).hostname
    : null

  return (
    <AuthProvider>
      <UserProvider>
        <Layout className="post-page" webview={webview}>
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
            <title>Jevitty News | {title}</title>
          </Helmet>

          <div className={webview ? undefined : 'row'}>
            <div className={webview ? undefined : 'col-lg-4'}>
              <LinkBack to={`/news/`} webview={webview}>
                &#8592; Back to News
              </LinkBack>

              <h1>{title}</h1>

              {(author || date) && (
                <PostAuthorAndDate
                  className="post-page__meta"
                  {...{ author, date }}
                />
              )}

              {topics && (
                <ul className="post-page__topics">
                  {topics.map(({ title }) => (
                    <li className="post-page__topics__item" key={title}>
                      {title}
                    </li>
                  ))}
                </ul>
              )}

              {originalArticleUrl && (
                <p>
                  Source:{' '}
                  <a href={originalArticleUrl}>{originalArticleDomain}</a>
                </p>
              )}
            </div>

            <div className={webview ? undefined : 'col-lg-8'}>
              {mainImage && (
                <img className="post-page__main-image" src={mainImage} alt="" />
              )}

              {body && (
                <div
                  className="post-page__body"
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                ></div>
              )}

              <ReactionButtons url={href} />
            </div>
          </div>
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
